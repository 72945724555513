import '../../const/styles.css';
import './contactStyles.css';
import ContactForm from './components/ContactForm.js';
import PhoneIcon from '../../assets/svg/PhoneIcon';
import MailIcon from '../../assets/svg/MailIcon';
import PinIcon from '../../assets/svg/PinIcon';
import ContactFormQR from '../../assets/svg/ContactFormQR.js';

export default function Contact() {
    return (
        <div className='contact-view'>
            <p className='text__heading'>Give us a shout, and we'll sort it out</p>
            <p className='text__subheading'>Get in touch with us by completing our 30-second form below!</p>
            

            

            <div className='contact_methods'>

                <a
                    className='anchor_button_styling anchor_button_styling__additional_styles text__highlighted button__highlighted'
                    href='https://forms.office.com/r/jnxSebB7wD'
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Click on me to go to our form
                </a>

                <div className='contact_methods__qr_code_container'>
                    <div className='qr_code'>
                        <ContactFormQR width="300px" height="300px" fill="black" />
                    </div>
                </div>


            </div>

            {/* <div className='contact__details'>
                <div className='footer_contact_details'>
                    <div className='footer_contact_details__item'>
                        <PhoneIcon width="10" height="10" />
                        <h7>07809220621</h7>
                    </div>
                    <div className='footer_contact_details__item'>
                        <MailIcon width="10" height="10" />
                        <h7>contact@svarex.co.uk</h7>
                    </div>
                    <div className='footer_contact_details__item'>
                        <PinIcon width="10" height="10" />
                        <h7>SE LDN</h7>
                    </div>
                </div>
                
            </div> */}

            

            {/* <ContactForm /> */}

        </div>
    )
}