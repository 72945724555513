import './about.css';
import '../../const/styles.css';
import TreeArt from '../../assets/svg/TreeArt';

export default function About() {
    return (
        <div className="about">
            <p className='text__heading'>Who We Are</p>
            <h5>A team dedicated to bringing the best out of your property. With quality and transparency in mind, we tailor to your needs and wishes to realise your visions.</h5>
            <h5>With over a decade of experience and a magnitude of satisfied customers, we are sure you would be too.</h5>
            <h5>Don’t believe us?</h5>
            
            {/* Read customer reviews Button */}
            <button className='button button--reviews' onClick={() => {
                const section = document.querySelector('.reviews')
                section.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
                }); // https://stackoverflow.com/a/62173627
            }}>
                Read customer reviews
            </button>

            <div className='tree_art_container'>
                <TreeArt width={557/1} height={422/1} />
            </div>
            <h5>We drive towards sustainability, choosing more sustainable options where available.</h5>
            <button className='button button--sustainability'>Learn more about sustainability</button>

        </div>
    )
}

