import './background_glow.css';

export default function BackgroundGlow() {
    return (
        <div className='background_glow_container'>
            <div className='background_glow_container__purple'></div>
            <div className='background_glow_container__blue'></div>
        </div>
    );
}

