import './hero.css';

export default function Hero() {
    return (
        <div className='hero'>

            <h4 className='hero__text'>
                For Refurbishment
            </h4>
            <h4 className='hero__text'>
                Repair or
            </h4>
            <h4 className='hero__text'>
                Construction
            </h4>

            <h1 className='hero__logo'>SVAREX</h1>

            <h2 className='hero__slogan'>Done Right. Simple.</h2>

            {/* Connect with us */}
            <button className='hero__button' onClick={() => {
                const section = document.querySelector('.contact')
                section.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
                }); // https://stackoverflow.com/a/62173627
            }}>
                Connect with us
            </button>
            {/* About us */}
            <button className='hero__button' onClick={() => {
                const section = document.querySelector('.about')
                section.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
                }); // https://stackoverflow.com/a/62173627
            }}>
                About us
            </button>
            
        </div>
    );
}
