import './header.css';

export default function Header() {
    return (
        <div className="header-container">
            <h3 className='logo'>SVAREX</h3>
            <nav>
                <ul className='nav__links'>
                    {/* ABOUT button */}
                    <button onClick={() => {
                        const section = document.querySelector('.about')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        about
                    </button>
                    {/* SERVICES button */}
                    <button onClick={() => {
                        const section = document.querySelector('.services')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        services
                    </button>
                    {/* TESTIMONIALS button */}
                    <button onClick={() => {
                        const section = document.querySelector('.reviews')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        testimonials
                    </button>
                    {/* CONTACT button */}
                    <button onClick={() => {
                        const section = document.querySelector('.contact')
                        section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        }); // https://stackoverflow.com/a/62173627
                    }}>
                        contact
                    </button>
                </ul>
            </nav>
        </div>
    );
}
