import './contactform.css';
import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';


const schema = z.object({
    name: z.string().min(3),
    email: z.string().email(),
    subject: z.string().min(3),
    message: z.string().min(3),
})

export default function ContactForm() {

    const [value, changeValue] = useState('');

    const {
        register,
        handleSubmit,
        formState:
            { errors, isSubmitting }
    } = useForm({
        mode: "onTouched",
        resolver: zodResolver(schema),
    });

    const onSubmit = async (data) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        data.target.reset(); // reset input fields
        console.log(data)
    };

    return (
        <div >          
            <form onSubmit={handleSubmit(onSubmit)} className='contact-form'>
                <div className='contact-form-contents-div'>
                    
                    {/* ----- NAME ----- */}
                    <div className='contact-form-input-div'>
                        <input 
                            {...register('name')}
                            className={errors.name ? "contact-form-input-invalid" : "contact-form-input-valid"}
                            type='text'
                            placeholder='Your Name (Required)'
                        />
                        {errors.name && (
                            <span className='contact-form-error'>{errors.name.message}</span>
                        )}
                    </div>

                    {/* ----- EMAIL ----- */}
                    <div className='contact-form-input-div'>
                        <input
                            {...register('email')}
                            className={errors.email ? "contact-form-input-invalid" : "contact-form-input-valid"}
                            type='text'
                            placeholder='Your Email (Required)'
                        />
                        {errors.email && (
                            <span className='contact-form-error'>Email must be in the form someone@domain.com</span>
                        )}
                    </div>


                    {/* ----- SUBJECT ----- */}
                    <div className='contact-form-input-div'>
                        <input
                            {...register('subject')}
                            className={errors.subject ? "contact-form-input-invalid" : "contact-form-input-valid"}
                            type='text'
                            placeholder='Subject (Required)'
                            />
                        {errors.subject && (
                            <span className='contact-form-error'>{errors.subject.message}</span>
                        )}
                    </div>


                    {/* ----- MESSAGE ----- */}
                    {/* TODO: fix so that message box can only resize height, and NOT width */}
                    <div className='contact-form-input-div'>
                        <textarea
                            {...register('message')}
                            className={errors.message ? "contact-form-input-invalid" : "contact-form-input-valid"}
                            placeholder='Your Message (Required)'
                            rows="10"
                            // cols="40"
                        />

                        {errors.message && (
                            <span className='contact-form-error'>{errors.message.message}</span>
                        )}
                    </div>


                    {/* ----- SUBMIT ----- */}
                    <div className='contact-form-input-div'>
                        <button
                            className='contact-form-submit-button'
                            disabled={isSubmitting}
                            type='submit'
                        >
                            {isSubmitting ? "Sending..." : "Send"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}


