import './footer.css';
import PhoneIcon from '../../assets/svg/PhoneIcon';
import MailIcon from '../../assets/svg/MailIcon';
import PinIcon from '../../assets/svg/PinIcon';

export default function Footer() {
    return (
        <div className='footer-view'>

            <h3 className='logo'>SVAREX</h3>
        
            {/* <h5 className='logo'>Refurbishment - Repair - Construction</h5> */}

            <div className='footer_contact_details'>
                <div className='footer_contact_details__item'>
                    <PhoneIcon width="35" height="35" />
                    <h7>07809220621</h7>
                </div>
                <div className='footer_contact_details__item'>
                    <MailIcon width="35" height="35" />
                    <h7>alex@svarex.co.uk</h7>
                </div>
                <div className='footer_contact_details__item'>
                    <PinIcon width="35" height="35" />
                    <h7>SE LDN</h7>
                </div>
            </div>
            
        </div>
    )
}

